import "vanilla-cookieconsent/dist/cookieconsent.css"
import * as CookieConsent from "vanilla-cookieconsent"

// microsoft logo
const logo = "MTR Markgräfler Treuhand & Revision GmbH"
const cookie3 = "🍪"

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: "cloud",
      position: "bottom",
      equalWeightButtons: false,
      flipButtons: false
    }
  },
  categories: {
    necessary: {
      readOnly: true,
      enabled: true
    }
  },
  language: {
    default: "de",
    translations: {
      de: {
        "consentModal": {
          "title": cookie3 + "&nbsp;&nbsp;Wir verwenden Cookies! ",
          "description": "Hallo, diese Website verwendet essentielle Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten. <a href='/datenschutz'>Datenschutzrichtlinien</a>",
          "acceptAllBtn": "Ok"
        }
      }
    }
  }
})
